.fishboneChart {
  display: flex;
  justify-items: center;
  min-height: 200px;
}

.top {
  border-bottom: 2px solid;
  border-radius: 5%;
}

.bottom {
  border-top: 2px solid;
  border-radius: 5%;
}

.left {
  border-left: 2px solid;
}

.all {
  border: 2px solid;
}

.causes {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.causesGroup {
  flex: 1;
  display: flex;
  margin-right: 10px;
}

.cause {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  font-size: 0.99em;
  font-weight: 400;
  color: #ffffff;
  padding: 5px 0px;
  font-family: "Poppins";
}

.causeAndLine {
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.rootCauses {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 3;
  font-size: 0.99em;
  font-weight: 400;
  color: #000000;
  text-align: right;
  padding: 15px 0px;
}

.legend {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 10px;
}

.legendLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  font-size: 0.99em;
  font-weight: 400;
  margin-bottom: 6px;
  cursor: default;
}

.blue_ {
  background: #4588ed;
}

.black_ {
  background: rgb(17, 17, 17, 0.1);
}

.blue_two_ {
  background: rgb(11, 120, 206, 0.1);
}

.gray_ {
  background: rgb(104, 115, 140, 0.1);
}

.green_ {
  background: rgb(48, 187, 187, 0.1);
}

.pink_ {
  background: rgb(191, 4, 73);
}

.purple_ {
  background: rgb(85, 82, 153, 0.1);
}

.orange_ {
  background: rgb(255, 119, 1, 0.1);
}

.blue_dark {
  background: #3386ff;
}

.black_dark {
  background: rgb(17, 17, 17, 0.4);
}

.blue_two_dark {
  background: rgb(11, 120, 206, 0.4);
}

.gray_dark {
  background: rgb(104, 115, 140, 0.4);
}

.green_dark {
  background: rgb(48, 187, 187, 0.4);
}

.pink_dark {
  background: rgb(191, 4, 73);
}

.purple_dark {
  background: rgb(85, 82, 153, 0.4);
}

.orange_dark {
  background: rgb(255, 119, 1, 0.4);
}

.blueBorder {
  border-color: #071d3e !important;
}

.blackBorder {
  border-color: rgb(17, 17, 17) !important;
}

.blue_twoBorder {
  border-color: #3386ff !important;
}

.grayBorder {
  border-color: rgb(104, 115, 140) !important;
}

.greenBorder {
  border-color: rgb(48, 187, 187) !important;
}

.pinkBorder {
  border-color: rgb(75, 2, 29) !important;
}

.purpleBorder {
  border-color: rgb(85, 82, 153) !important;
}

.orangeBorder {
  border-color: rgb(255, 119, 1) !important;
}

.labelSquare {
  flex: 1;
  width: 42px;
  height: 14px;
  margin: 0px 4px 0px 4px;
}

.labelLineThrough {
  text-decoration: line-through;
  font-size: 1em;
  font-weight: 400;
  color: #000000;
}

.label_ {
  font-size: 0.99em;
  font-weight: 400;
  color: #000000;
}

.effect {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2ch;
}

.effectValue {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  font-weight: 400;
  color: #ffffff;
  padding: 5px 10px 5px 10px;
}

.diagonalLine {
  flex: 1;
  height: 100%;
}

.blueTopBottom {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 2px),
    #3386ff,
    transparent calc(50% + 1.5px)
  );
}

.blueBottomTop {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 2px),
    #3386ff,
    transparent calc(50% + 1.5px)
  );
}

.blackTopBottom {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 2px),
    rgb(17, 17, 17),
    transparent calc(50% + 1.5px)
  );
}

.blackBottomTop {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 2px),
    rgb(17, 17, 17),
    transparent calc(50% + 1.5px)
  );
}

.blue_twoTopBottom {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 2px),
    rgb(11, 120, 206),
    transparent calc(50% + 1.5px)
  );
}

.blue_twoBottomTop {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 2px),
    rgb(11, 120, 206),
    transparent calc(50% + 1.5px)
  );
}

.grayTopBottom {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 2px),
    rgb(104, 115, 140),
    transparent calc(50% + 1.5px)
  );
}

.grayBottomTop {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 2px),
    rgb(104, 115, 140),
    transparent calc(50% + 1.5px)
  );
}

.greenTopBottom {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 2px),
    rgb(48, 187, 187),
    transparent calc(50% + 1.5px)
  );
}

.greenBottomTop {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 2px),
    rgb(48, 187, 187),
    transparent calc(50% + 1.5px)
  );
}

.pinkTopBottom {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 2px),
    rgb(191, 4, 73),
    transparent calc(50% + 1.5px)
  );
}

.pinkBottomTop {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 2px),
    rgb(191, 4, 73),
    transparent calc(50% + 1.5px)
  );
}

.purpleTopBottom {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 2px),
    rgb(85, 82, 153),
    transparent calc(50% + 1.5px)
  );
}

.purpleBottomTop {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 2px),
    rgb(85, 82, 153),
    transparent calc(50% + 1.5px)
  );
}

.orangeTopBottom {
  background: linear-gradient(
    to top right,
    transparent calc(50% - 2px),
    rgb(255, 119, 1),
    transparent calc(50% + 1.5px)
  );
}

.orangeBottomTop {
  background: linear-gradient(
    to bottom right,
    transparent calc(50% - 2px),
    rgb(255, 119, 1),
    transparent calc(50% + 1.5px)
  );
}

.lineEffect {
  width: 100%;
  border-bottom: 2px solid;
}

.causeContent {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50%;
}
