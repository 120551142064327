.tree {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}

.tree ul {
  margin-left: 1em; /* Adjust as needed */
  position: relative;
  padding-left: 1em;
}

.tree ul ul {
  margin-left: 0.5em; /* Adjust as needed */
}

.tree li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
  padding-left: 20px;
}

.tree li::before,
.tree li::after {
  content: "";
  left: -20px;
  position: absolute;
  right: auto;
}

.tree li::before {
  border-left: 1px solid #000; /* Line color */
  bottom: 50px;
  height: 100%;
  top: 0;
  width: 1px;
}

.tree li::after {
  border-top: 1px solid #000; /* Line color */
  height: 20px;
  top: 20px;
  width: 25px;
}

.tree li:last-child::before {
  height: 20px;
}

.tree li:last-child::after {
  height: 0;
}

/* KnowledgeMap.css */

.floating-buttons {
  position: fixed;
  top: 3cm;
  right: 2cm;
  background-color: #f2f2f9;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensure it's above other content */
}

.floating-buttons p {
  margin: 0 0 5px;
}

.floating-buttons button {
  margin-right: 5px;
  padding: 8px 12px;
  background-color: #4285f4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.floating-buttons button:hover {
  background-color: #0056b3;
}
